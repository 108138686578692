import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import { AccountActivation } from '../../components/pages';
import { accountActivation } from '../../api/v1/post';

const AccountActivationPage = () => {
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({
    password: '',
    confirmationPassword: '',
  });
  const [accountActivationStatus, setAccountActivationStatus] = useState({
    isPasswordMatch: true,
    isSubmitting: false,
    isError: false,
    isSuccessModalShown: false,
  });
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAccountActivationStatus((prevState) => ({
      ...prevState,
      isSubmitting: true,
    }));
    setErrorMessage('');

    if (formData.password !== formData.confirmationPassword) {
      setAccountActivationStatus((prevState) => ({
        ...prevState,
        isSubmitting: false,
        isPasswordMatch: false,
      }));
    } else {
      try {
        const actionToken = searchParams.get('action_token');

        await accountActivation({
          action_token: actionToken,
          new_password: formData.password,
          retype_password: formData.confirmationPassword,
        });
        setAccountActivationStatus((prevState) => ({
          ...prevState,
          isPasswordMatch: true,
          isSubmitting: false,
          isError: false,
          isSuccessModalShown: true,
        }));
      } catch (error) {
        setErrorMessage(
          `Oops there is an error! Code: ${error.response.status}, Message: ${error.response.data.message}`,
        );
      } finally {
        setAccountActivationStatus((prevState) => ({
          ...prevState,
          isSubmitting: false,
          isPasswordMatch: true,
        }));
      }
    }
  };

  return (
    <Layout isHeaderShown={false} isPaddingActive={false}>
      <AccountActivation
        formData={formData}
        accountActivationStatus={accountActivationStatus}
        setAccountActivationStatus={setAccountActivationStatus}
        errorMessage={errorMessage}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </Layout>
  );
};

export default AccountActivationPage;
